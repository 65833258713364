.game {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
}

.game img {
    width: 64px;
}

.wrap {
    text-align: center;
}

.buttonWrap {
    width: 126px;
    margin: auto;
}

.header {
    display: flex;
    justify-content: space-around;
    font-weight: 900;
    font-size: 4rem;
}